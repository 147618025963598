@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductListWithoutHotspotsWidget {
    @include desktop {
        padding-block-end: 48px;
    }

    &-Header {
        display: flex;
        align-items: center;
        margin-block: -8px 16px;

        @include desktopAndAbove {
            margin-block-start: -40px;
            margin-block-end: 32px;
        }
    }

    &-SeeAll {
        position: absolute;
        inset-inline-end: 0;
        padding-block-end: 3px;
        color: $color-primary;
        font-size: $fs-xs;
        font-weight: $fw-medium;
        border-bottom: 1px solid $color-primary;
    }

    &-TopNavigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 108px;
        padding-inline: 9px;
        padding-block: 2px;
        border: 1px solid $gray-light;
        background: $white;

        @include desktopAndAbove {
            margin-inline: auto;
        }

        span {
            display: block;
            width: 18px;
            text-align: center;
            font-weight: $fw-regular;
            font-size: $fs-xxs;
            line-height: 15px;
            color: $scrollbar-thumb-color;
        }

        &-Next,
        &-Previous {
            display: flex;
            cursor: pointer;

            &[disabled] {
                svg {
                    path {
                        fill: $gray6;
                    }
                }
            }
        }
    }

    &-SideNavigation {
        &-Previous,
        &-Next {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            inset-block-start: 50%;
            border: 1px solid $gray-light;
            background: $white;
            height: 64px;
            width: 64px;
            z-index: 5;
            cursor: pointer;

            @include mobileAndTablet {
                @include visually-hidden;
            }

            svg {
                path {
                    fill: $black;
                }
            }

            &:hover,
            &:focus {
                svg {
                    path {
                        fill: $color-primary;
                    }
                }
            }
        }

        &-Previous {
            inset-inline-start: 0;
            transform: translate(-50%, calc(-50% - 104px));
        }

        &-Next {
            transform: translate(50%, calc(-50% - 104px));
            inset-inline-end: 0;
        }
    }

    .swiper-pagination {
        margin-block-start: 32px;
        height: 2px;
        background: $scrollbar-track-color;

        @include mobileAndTablet {
            @include visually-hidden;
        }

        &-progressbar-fill {
            position: absolute;
            inset-inline-start: 0;
            inset-block-start: 0;
            width: 100%;
            height: 2px;
            transform-origin: left;
            background: $color-primary;
        }
    }

    &-Product {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr;
        grid-column-gap: 42px;

        @include mobileAndTablet {
            margin-block-end: auto;
        }

        &-Content {
            @include mobileAndTablet {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-block: 16px;
                order: 1;
                grid-gap: 12px;
            }
        }

        @include desktopAndAbove {
            grid-template-columns: auto 74%;
            grid-column-gap: 42px;
        }

        &-Wrapper {
            display: flex;
            flex-direction: column;
            height: auto;
        }

        h3.ProductListWithoutHotspotsWidget-Product-Name {
            margin-block: 0;

            @include mobileAndTablet {
                font-weight: $fw-regular;
                font-size: $fs-m;
                line-height: 24px;
            }

            @include desktopAndAbove {
                margin-block-end: 24px;
            }
        }

        &-Discount {
            &-Label {
                margin-block-end: 9px;
                font-weight: $fw-regular;
                font-size: $fs-xs;
                line-height: 24px;
                color: $error;

                @include mobileAndTablet {
                    @include visually-hidden;
                }
            }

            &-Value {
                display: inline-flex;
                align-self: flex-start;
                justify-self: flex-start;
                padding-inline: 12px;
                padding-block: 9px;
                border: 1px solid $error;
                font-weight: $fw-semi-bold;
                font-size: $fs-xs;
                line-height: 17px;
                color: $error;

                @include mobile {
                    white-space: nowrap;
                }
            }
        }

        &-Description {
            height: 72px;
            overflow: hidden;
            margin-block-end: 24px;
        }

        &-Information {
            background: $color-primary;
            padding-inline: 16px;
            padding-block: 16px;

            @include mobileAndTablet {
                text-align: center;
            }

            @include desktopAndAbove {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            h4 {
                margin-block: 0;
                font-weight: $fw-medium;
                color: $white;


                @include mobileAndTablet {
                    margin-block-end: 13px;
                }
            }

            p {
                margin-block: 0;
                font-weight: $fw-light;
            }

            .Button {
                --hollow-button-background: #{$color-tertiary};
                --hollow-button-hover-background: #{$color-tertiary};
                --hollow-button-padding: 36px;
                --hollow-button-hover-padding: 36px;

                border: none;
            }
        }

        &-Gallery {
            display: grid;
            grid-gap: 8px;

            @include desktopAndAbove {
                grid-template-columns: auto 66.8%;
            }

            &_isBigImage {
                @include desktopAndAbove {
                    grid-template-columns: 66.8% auto;
                }
            }

            &Small {
                display: grid;
                grid-gap: 8px;

                @include mobileAndTablet {
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            .ProductLabel {
                inset-inline-start: 16px;
                inset-block-start: 16px;
                margin: 0;
            }
        }
    }
}

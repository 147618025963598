@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductBuiltListSlider {
    padding-inline: 20px;
    padding-block: 20px;
    border: 1px solid $gray-light;

    @include mobile {
        padding: 0;
        border: 0;
    }

    &-Wrapper {
        margin-block-end: 16px;

        @include desktopAndAbove {
            margin-block-start: -10px;
        }

        h4 {
            font-weight: $fw-regular;
            font-size: $fs-xs;
            line-height: 20px;
            margin-block-end: 9px;

            @include desktopAndAbove {
                margin-block-start: 16px;
            }

            @include above1200 {
                margin-block-start: 0;
            }
        }
    }

    &-SideNavigation {
        &-Next,
        &-Previous {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            inset-block-start: 50%;
            border: 1px solid $gray-light;
            background: $white;
            height: 32px;
            width: 32px;
            transform: translateY(-50%);
            z-index: 5;
            cursor: pointer;

            @include mobileAndTablet {
                @include visually-hidden;
            }

            svg {
                height: 18px;
                width: 18px;

                path {
                    fill: $black;
                }
            }

            &:hover,
            &:focus {
                svg {
                    path {
                        fill: $color-primary;
                    }
                }
            }
        }

        &-Previous {
            inset-inline-start: 20px;
        }

        &-Next {
            inset-inline-end: 20px;
        }
    }

    &-Product {
        display: grid;
        grid-template-columns: 80px auto;
        grid-column-gap: 24px;
        align-items: center;
        align-self: center;
        flex-grow: 1;

        @include mobile {
            border: 1px solid $gray-light;
            padding: 10px;
        }

        &-Name {
            height: 40px;
            overflow: hidden;
            font-weight: $fw-regular;
            font-size: $fs-xs;
            line-height: 20px;
            margin-block: 0;
        }

        &-Price {
            min-height: auto;
            margin-block-end: 8px;
            font-weight: $fw-medium;
            font-size: $fs-xl;
            line-height: 30px;

            .ProductPrice-PriceValue {
                margin-block-start: 0;
            }
        }

        .ProductPrice {
            @include mobile {
                @include flex($dir: column);

                margin-block-end: 4px;
            }
        }

        &.swiper-slide-active {
            @include mobile {
                /* stylelint-disable declaration-no-important */
                width: 80% !important;
            }
        }
    }
}
